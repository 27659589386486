<template>
  <c-header fixed light>
    <c-toggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.dispatch('sidebar/toggleMobile')"
    />
    <c-toggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.dispatch('sidebar/toggleDesktop')"
    />
    <c-breadcrumb-router class="border-0 mb-0 my-auto" />
    <c-button class="ml-auto" @click="$store.dispatch('user/logout')">
      logout
    </c-button>
  </c-header>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>
