export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Analytics']
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Dashboard',
      //   to: '/',
      //   icon: 'cil-speedometer'
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Organizations',
        to: '/orgs',
        icon: 'cil-building',
        exact: false
      }
    ]
  }
]
