<template>
  <c-sidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="value => $store.commit('sidebar/SET', ['show', value])"
  >
    <c-sidebar-brand class="d-md-down-none" to="/">
      <c-icon
        class="d-block p-1"
        name="logo"
        size="custom-size"
        :height="35"
        :viewBox="`0 0 ${minimize ? 48 : 192} 32`"
      />
    </c-sidebar-brand>

    <c-render-function flat :content-to-render="$options.nav" />
    <c-sidebar-minimizer
      class="d-md-down-none"
      @click.native="$store.commit('sidebar/SET', ['minimize', !minimize])"
    />
  </c-sidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'AppSidebar',
  nav,
  computed: {
    show() {
      return this.$store.state.sidebar.show
    },
    minimize() {
      return this.$store.state.sidebar.minimize
    }
  }
}
</script>
