<template>
  <div class="c-app">
    <app-sidebar />
    <c-wrapper>
      <app-header />
      <div class="c-body">
        <main class="c-main">
          <c-container fluid>
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </c-container>
        </main>
        <app-footer />
      </div>
    </c-wrapper>
  </div>
</template>

<script>
import AppSidebar from './AppSidebar'
import AppHeader from './AppHeader'
import AppFooter from './AppFooter'

export default {
  name: 'AppContainer',
  components: {
    AppSidebar,
    AppHeader,
    AppFooter
  }
}
</script>
